<template>
  <b-row>
    <b-col>
      <b-card class="p-1 p-md-3">

        <b-card-title>
          {{ $t('withdraw-text') }}
        </b-card-title>
        <b-row>
          <b-col
            sm="12"
          >
            <b-alert
              variant="success"
              show
            >

              <div class="alert-body p-2">
                <h4>
                  {{ $t('withdraw-alert-text') }}
                </h4>
              </div></b-alert>

          </b-col>
        </b-row>
        <b-row>
          <div class="d-flex flex-column flex-md-row  w-100 justify-content-center align-items-center">
            <b-button
              class="d-flex justify-content-center align-items-center btn-style"
              variant="success"
              :to="{name:'forexModule-bank-withdrawal',params:{id:$route.params.id}}"
            >
              <h4
                class="text-white"
              >
                {{ $t('fiat_withdraw') }}
              </h4>
            </b-button>

            <b-button
              class="d-flex justify-content-center align-items-center btn-style "
              variant="info"
              :to="{name:'forexModule-crypto-withdrawal',params:{id:$route.params.id}}"
            >
              <h4
                class="text-white"
              >
                {{ $t('crypto-withdraw') }}
              </h4>
            </b-button>
          </div>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BButton, BRow, BCol, BCardTitle, BAlert,
} from 'bootstrap-vue'

export default {
  components: {

    BCard,
    BButton,
    BRow,
    BCol,
    BCardTitle,
    BAlert,
  },

  data() {
    return {

    }
  },
}
</script>
    <style>
    .btn-style{
      margin: 5px;
       width:100% ;
       height: 100px;
    }
    </style>
